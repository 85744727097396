<template>
  <div class="container">
    <span class="message">
      <div class="message-container">
        <div class="message-hidden">{{ message }}</div>
        <div class="full-message">
          <div class="displayed-message">
            {{ messageDisplayed }}
          </div>
          <span class="letter-to-add" :key="letterToAddKey" v-if="letterToAdd">
            {{ letterToAdd }}
          </span>
        </div>
      </div>
    </span>
  </div>
</template>

<script>
export default {
  name: 'MessageBubble',
  props: {
    message: {
      type: String
    },
    typeSpeed: {
      type: Number,
      default: 50
    }
  },
  data() {
    return {
      letterToAdd: '',
      messageDisplayed: '',
      letterToAddKey: '',
      index: 0
    }
  },
  methods: {
    addLetter() {
      if (this.message && this.message.length > this.index) {
        const previousLetter = this.letterToAdd
        this.messageDisplayed = this.messageDisplayed + previousLetter
        this.letterToAdd = this.message[this.index + 1 || '']
        this.letterToAddKey = Date.now().toString(36)

        this.index = this.index + 1
        setTimeout(this.addLetter, this.typeSpeed)
      } else {
        this.$emit('message-displayed')
      }
    }
  },
  watch: {
    message(newMessage) {
      if (newMessage) {
        this.messageDisplayed = ''
        this.letterToAdd = ''
        this.index = 0
        this.letterToAddKey = ''
        this.addLetter()
      }
    }
  },
  mounted() {
    this.addLetter()
  }
}
</script>

<style scoped lang="scss">
.container {
  font-family: 'Noto Sans JP', sans-serif;
  width: 1000%;
  overflow: hidden;
}
.message {
  display: inline-flex;

  .message-container {
    position: relative;
    border-radius: 5px;
    background-color: #212121;
    color: white;
    padding: 0.5rem;
    display: flex;
    align-items: center;

    .full-message {
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      right: 0.5rem;
      bottom: 0.5rem;
      display: flex;
    }

    .message-hidden {
      visibility: hidden;
    }

    .displayed-message {
    }
  }
}

.letter-to-add {
  animation: enter-letter backwards 0.1s;
}

@keyframes enter-letter {
  from {
    transform: rotate(-20deg) scale(1.1);
  }
}
</style>
