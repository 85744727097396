<template>
  <div id="app">
    <BackseatMe />
  </div>
</template>

<script>
import BackseatMe from './components/BackseatMe'

export default {
  name: 'App',
  components: {
    BackseatMe
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap');
body,
div {
  margin: 0;
  padding: 0;
}
body {
  overflow: hidden;
}
</style>
