<template>
  <div class="character-container">
    <div class="name">{{ name }}</div>
    <div :style="characterStyle" ref="character" class="character" />
    <transition name="message" mode="out-in">
      <MessageBubble
        v-if="message"
        :key="message"
        :message="message"
        ref="message"
        class="message"
        @message-displayed="$emit('message-displayed')"
      />
    </transition>
  </div>
</template>

<script>
import animations from '../assets/animations.png'
import MessageBubble from './MessageBubble'

const ANIMATION_HEIGHT = 48
const NB_ROW_PER_CHARACTER = 2

export default {
  name: 'Character',
  components: { MessageBubble },
  props: {
    type: {
      type: String,
      validator: (v) => ['slime', 'warrior'].includes(v),
      default: 'slime'
    },
    name: {
      type: String
    },
    animation: {
      type: String,
      validator: (v) => ['idle', 'walk', 'talk'].includes(v),
      default: 'idle'
    },
    direction: {
      type: String,
      validator: (v) => ['left', 'right'].includes(v),
      default: 'right'
    },
    message: {
      type: String
    }
  },
  data() {
    return {
      messagePopper: null
    }
  },
  computed: {
    characterHeightOffset() {
      switch (this.type) {
        case 'warrior':
          return 0

        case 'slime':
          return ANIMATION_HEIGHT * NB_ROW_PER_CHARACTER
      }
      return 0
    },
    animationOffset() {
      switch (this.animation) {
        case 'idle':
        case 'walk':
          return 0

        case 'talk':
          return ANIMATION_HEIGHT
      }
      return 0
    },
    currentAnimationHeightOffset() {
      return this.animationOffset + this.characterHeightOffset
    },
    characterStyle() {
      return {
        background: `url(${animations}) 0 0`,
        height: `${ANIMATION_HEIGHT}px`,
        width: `${ANIMATION_HEIGHT}px`,
        backgroundPositionY: `${-this.currentAnimationHeightOffset}px`,
        backgroundSize: `${ANIMATION_HEIGHT * NB_ROW_PER_CHARACTER * 2}px`,
        animation:
          this.animation === 'idle'
            ? ''
            : 'animate-full infinite 0.5s steps(4)',
        transform: this.direction === 'right' ? '' : 'scaleX(-1)'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.character-container {
  display: inline-flex;
  position: relative;
  transform: scale(1.5);

  .character {
    height: 24px;
  }
}

.name {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
}

.message-enter-active,
.message-leave-active {
  transition: opacity 0.2s;
}
.message-enter, .message-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.message {
  position: absolute;
  left: 100%;
  top: 0;
}
</style>

<style>
@keyframes animate-full {
  100% {
    background-position-x: -192px;
  }
}

@keyframes animate-half {
}
</style>
