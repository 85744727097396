<template>
  <div class="main-container">
    <div class="message-test" :style="characterStyle" ref="character">
      <Character
        v-if="user && user.backseater.info && characterType"
        :type="characterType"
        :animation="animation"
        :message="(lastMessage && lastMessage.msg) || ''"
        :name="user.backseater.info.display_name"
        @message-displayed="onMessageDisplayed"
        :direction="characterDirection"
      />
    </div>
  </div>
</template>

<script>
import ComfyJS from 'comfy.js'
import Character from './Character'
import animations from '../assets/animations.png'
import axios from 'axios'

export default {
  name: 'BackseatMe',
  components: { Character },
  data() {
    return {
      animations,
      lastMessage: '',
      messageQueue: [],
      lastMouseClick: { x: 0, y: 0 },
      characterDirection: 'right',
      isCharacterMoving: false,
      refreshInterval: 0,
      user: null,
      userToLoad: null
    }
  },
  computed: {
    characterType() {
      if (this.user && this.user.backseater && this.user.backseater.emote) {
        if (this.user.backseater.emote.type === 'local') {
          switch (this.user.backseater.emote.id) {
            case 1:
              return 'warrior'
            case 2:
              return 'slime'
          }
        }
      }
      return ''
    },
    characterStyle() {
      return {
        top: `${this.lastMouseClick.y}%`,
        left: `${this.lastMouseClick.x}%`
      }
    },
    channelToJoin() {
      if (this.user) {
        console.log(this.user)
        return this.user.backseater.info.login
      }
      return ''
    },
    animation() {
      if (this.isCharacterMoving) {
        return 'walk'
      }
      if (this.lastMessage) {
        return 'talk'
      } else {
        return 'idle'
      }
    }
  },
  methods: {
    onMouseClick(e) {
      this.lastMouseClick = {
        x: e.clientX,
        y: e.clientY
      }
    },
    onMessageDisplayed() {
      setTimeout(() => {
        if (this.messageQueue.length) {
          this.lastMessage = this.messageQueue.pop()
        } else {
          this.lastMessage = ''
        }
      }, 2500)
    }
  },
  watch: {
    userToLoad(newUser) {
      ComfyJS.onChat = (sender, message, flags, self, extra) => {
        console.log({ extra, flags })
        if (
          sender.toLowerCase() ===
            this.user.backseater.info.login.toLowerCase() &&
          message.startsWith('@')
        ) {
          const user = message.substring(1, message.indexOf(' '))

          if (
            user.toLocaleLowerCase() ===
            this.user.channelInfo.login.toLocaleLowerCase()
          ) {
            const msg = message.substring(user.length + 1)
            if (this.lastMessage) {
              this.messageQueue.unshift({ id: extra.id, msg })
            } else {
              this.lastMessage = { id: extra.id, msg }
            }
          }
        }
      }
      ComfyJS.onMessageDeleted = (id) => {
        if (this.lastMessage && this.lastMessage.id === id) {
          // TODO remove current message and move to the next one
          this.lastMessage = null
          this.onMessageDisplayed()
          return
        }
        const msgIndex = this.messageQueue.find((m) => m.id === id)
        if (msgIndex !== -1) {
          this.messageQueue.splice(msgIndex, 1)
        }
      }
      ComfyJS.Init(newUser.channelInfo.login)
      this.refreshInterval = setInterval(() => {
        axios
          .get(
            `https://t9mienm7e2.execute-api.eu-west-1.amazonaws.com/v1/live/${newUser.channelId}`
          )
          .then((res) => {
            this.lastMouseClick = {
              x: res.data.backseater.emotePosition.x * 100,
              y: res.data.backseater.emotePosition.y * 100
            }
            this.user = res.data

            // TODO update emote
          })
      }, 1000)
    },
    lastMouseClick(newValue, oldValue) {
      this.characterDirection = newValue.x < oldValue.x ? 'left' : 'right'

      const fn = () => {
        this.isCharacterMoving = false
        this.$refs.character.removeEventListener('transitionend', fn)
      }
      this.$refs.character.addEventListener('transitionend', fn)
      this.isCharacterMoving = true
    }
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search)
    const channelId = urlParams.get('channel')
    if (channelId) {
      axios
        .get(
          `https://t9mienm7e2.execute-api.eu-west-1.amazonaws.com/v1/live/${channelId}`
        )
        .then((res) => {
          this.lastMouseClick = {
            x: res.data.backseater.emotePosition.x * 100,
            y: res.data.backseater.emotePosition.y * 100
          }
          this.userToLoad = res.data

          // TODO update emote
        })
    }
  },
  beforeDestroy() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval)
    }
  }
}
</script>

<style scoped lang="scss">
.main-container {
  background: transparent;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  display: flex;
}
.characters {
  display: flex;
}
.message-test {
  position: absolute;
  transition: all 1.5s cubic-bezier(0.65, 0, 0.35, 1);
}
</style>
